<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="formAction === 'select'">
      <div class="details">
        <img :src="contentForm.logopath" style="display: block; width: 100%;" />
      </div>
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ contentForm.profile_name }}</div>
      <div class="titles">{{ __("Sender email") }}</div>
      <div class="details">{{ contentForm.sender_email }}</div>

      <el-button
        @click="openModal = true"
        class="editBtn"
        :disabled="!can('manage.email-profiles.write')"
      >
        {{ __("Edit Email Profile") }}
      </el-button>
      <el-button
        @click="
          handleDelete(contentForm, 'EmailProfile', contentForm.profile_id)
        "
        class="deleteBtn"
        :disabled="!can('manage.email-profiles.write')"
        >{{ __("Delete") }}</el-button
      >

      <div style="margin-top: 20px;">
        <el-button
          plain
          @click.prevent="handleSendTestEmail(contentForm.profile_id)"
        >
          {{ __("Send test email") }}
        </el-button>
      </div>

      <delete-item></delete-item>
    </div>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      :show-close="false"
      top="6vh"
      destroy-on-close
      fullscreen
      v-loading="isSubmitting"
    >
      <div class="email-profile-form-dialog">
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header :title="__('Email Profile')" :contentId="id" />
          </el-col>
        </el-row>
        <el-scrollbar :native="false">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Name')" prop="profile_name">
                  <el-input v-model="contentForm.profile_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('SMTP Secure')" prop="smtp_secure">
                  <el-radio-group
                    v-model="contentForm.smtp_secure"
                    class="display-inline"
                  >
                    <el-radio label="NONE">NONE</el-radio>
                    <el-radio label="TLS">TLS</el-radio>
                    <el-radio label="SSL">SSL</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Host')" prop="smtp_host">
                  <el-input v-model="contentForm.smtp_host"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Port')" prop="smtp_port">
                  <el-input v-model="contentForm.smtp_port"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Username')" prop="smtp_username">
                  <el-input v-model="contentForm.smtp_username"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Password')" prop="smtp_password">
                  <el-input
                    v-model="contentForm.smtp_password"
                    type="password"
                    autocomplete="new-password"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Sender Email')" prop="sender_email">
                  <el-input v-model="contentForm.sender_email"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Sender Name')" prop="sender_name">
                  <el-input v-model="contentForm.sender_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item>
                  <save-button
                    :disabled="!can('manage.email-profiles.write')"
                    type="primary"
                    @click="submitForm"
                    class="submitBtn"
                    :primaryKey="id"
                  />
                  <el-button class="cancelBtn" @click="handleCancel"
                    >{{ __("Cancel") }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import DeleteItem from "@/components/DeleteItem";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    DeleteItem,
    SaveButton
  },

  props: {
    id: {
      type: [String, Number],
      required: false,
      default: null
    }
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },

  data() {
    return {
      isSubmitting: false,
      rules: {
        profile_name: [
          {
            required: true,
            message: __("Email profile name cannot be empty"),
            trigger: "blur"
          }
        ],
        smtp_host: [
          {
            required: true,
            message: __("Host cannot be empty"),
            trigger: "blur"
          }
        ],
        smtp_port: [
          {
            required: true,
            message: __("Port cannot be empty"),
            trigger: "blur"
          }
        ],
        sender_email: [
          {
            required: true,
            message: __("Email cannot be empty"),
            trigger: "blur"
          }
        ],
        sender_name: [
          {
            required: true,
            message: __("Name cannot be empty"),
            trigger: "blur"
          }
        ]
      },
      forceDeleteEventName: "initiate-force-delete-EmailProfile"
    };
  },

  methods: {
    ...mapActions("emailprofiles", {
      createEmailProfile: "createEmailProfile",
      updateEmailProfile: "updateEmailProfile",
      deleteContentMethod: "deleteEmailProfile",
      undoDeleteContent: "undoDeleteEmailProfile",
      sendTestEmail: "sendTestEmail"
    }),

    handleSendTestEmail(profile_id) {
      this.$prompt(__("Send a test email"), __("Email"), {
        confirmButtonText: __("Send"),
        cancelButtonText: __("Cancel"),
        inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: __("Invalid Email"),
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = `${__("Loading")}...`;
            this.sendTestEmail({
              profile_id: profile_id,
              email: instance.inputValue
            }).then(() => {
              instance.confirmButtonLoading = false;
              done();
            });
          } else {
            done();
          }
        }
      })
        .then(() => {
          this.$message({
            type: "success",
            message: __("Email sent")
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: __("Test send email cancelled")
          });
        });
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createEmailProfile : this.updateEmailProfile;

          this.contentForm.profile_id = this.content.profile_id;
          this.contentForm.ac_id = this.selectedAccountId;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Email Profile added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Email Profile updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    }
  },

  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });
  },

  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

.titles {
  color: #6e7681;
  font-weight: bold;
  margin-top: 10px;
}

.details {
  color: #a0a8b5;
  font-size: 0.85em;
  margin-top: 5px;
}

.submitBtn {
  height: 44px;
  background: $content-theme-color;
  border: none;
  &:hover {
    background: $content-theme-hover-color;
  }
}

.cancelBtn {
  border: none;
  height: 44px;
  &:hover {
    background: lightgrey;
    color: white;
  }
}

.editBtn {
  margin-top: 10px;
  height: 44px;
  background: $content-theme-color;
  border: none;
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  &:hover {
    color: white;
    background: $content-theme-hover-color;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
