<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "EmailProfilesManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("emailprofiles", {
      contents: state => state.emailProfiles
    })
  },

  methods: {
    initContent() {
      return {
        profile_name: "",
        smtp_secure: "NONE",
        smtp_host: "",
        smtp_port: "",
        smtp_username: "",
        smtp_password: "",
        sender_email: "",
        sender_name: ""
      };
    }
  },

  mounted() {
    this.contentType = "EmailProfile";
    this.primaryKey = "profile_id";
    this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
