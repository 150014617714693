<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      :title="__('Email Profiles')"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      class="absolute"
    />
    <el-button
      class="createBtn"
      @click.stop="createContentItem"
      :disabled="!can('manage.email-profiles.write')"
      >{{ __("Create Email Profile") }}</el-button
    >
    <div style="padding: 0 36px; margin-top: 136px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="emailProfilesTable"
            class="list-table"
            :data="emailProfiles"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            v-loading="loading"
            v-show="can('manage.email-profiles.read')"
          >
            <el-table-column :label="__('Name')" prop="profile_name" />
            <el-table-column :label="__('Smtp secure')" prop="smtp_secure" />
            <el-table-column :label="__('Smtp host')" prop="smtp_host" />
            <el-table-column :label="__('Sender email')" prop="sender_email" />
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "EmailProfilesManageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      search: "",
      sortByColumns: ["profile_name", "profile_id"]
    };
  },

  computed: {
    ...mapState("emailprofiles", {
      emailProfiles: state => state.emailProfiles,
      loading: state => state.loading
    })
  },

  mounted() {
    if (this.msg) {
      this.$message({
        type: "success",
        message: this.msg
      });
    }
  },

  methods: {
    ...mapActions("emailprofiles", {
      contentAPI: "getEmailProfiles",
      deleteContentMethod: "deleteEmailProfile",
      undoDeleteContent: "undoDeleteEmailProfile"
    }),

    handleClear() {
      this.$refs.emailProfilesTable &&
        this.$refs.emailProfilesTable.setCurrentRow();
      this.handleClearSelection();
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
      // this.handleSelect(null, row);
    },

    handleDeleteItem(index, account) {
      this.handleDelete(account);
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>
